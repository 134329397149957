import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Frontiers() {
  return (
    <>
      <div className="subpage-recuadro general-text flex-rec">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/frontiers-rec1.webp"
          }
          alt="img"
        />
        <div className="text-container">
            <h3 className="subpage-rec-title mt-0">
              <a
                className="link-int-page"
                href="https://schmidtocean.org/cruise/investigating-life-without-oxygen-in-the-tropical-pacific/"
                target="_blank"
              >
                Investigating Life Without Oxygen in the Tropical Pacific
              </a>
            </h3>
            <h4 className="subpage-rec-subtitle">
              Chief Scientist: Dr. Mak Saito, Woods Hole Oceanographic Institution
            </h4>
            <p>
              In 2016, scientists onboard R/V{" "}
              <span className="falkor-format">Falkor</span> collected nearly 100
              protein samples in the Central Pacific Ocean, which, to this day,
              represents the{" "}
              <a
                className="link-int-page"
                href="https://proteinportal.whoi.edu/about-datasets"
                target="_blank"
              >
                most significant contribution of data
              </a>{" "}
              to the{" "}
              <a
                className="link-int-page"
                href="https://proteinportal.whoi.edu/"
                target="_blank"
              >
                Ocean Protein Portal
              </a>
              . The protein dataset was recently{" "}
              <a
                className="link-int-page"
                href="https://www.pnas.org/eprint/I2DYFCVFGMJYAPJQHZRW/full"
                target="_blank"
              >
                used to investigate how microbial protein distributions change
                across time and space
              </a>
              . The research sheds light on previously understudied microbial
              energetic pathways that underlie Earth’s life-sustaining
              biogeochemical cycles.
            </p>
        </div>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/frontiers-rec2.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/discovering-deep-sea-corals-phoenix-islands/"
            target="_blank"
          >
            Discovering Deep Sea Corals of the Phoenix Islands
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Erik Cordes, Temple University
        </h4>
        <p>
          In 2017, R/V <span className="falkor-format">Falkor</span> traveled to
          the Phoenix Islands Protected Area,{" "}
          <a
            className="link-int-page"
            href="https://whc.unesco.org/en/list/1325/#:~:text=The%20Phoenix%20Island%20Protected%20Area,Protected%20Area%20in%20the%20world."
            target="_blank"
          >
            currently the largest and deepest UNESCO World Heritage Site
          </a>
          , to explore and document never-before-seen seamounts and atolls. The
          primary research goal was to study the deep-sea corals that live on
          these features for the first time. In addition, samples of deep-sea
          bacteria were collected, analyzed, and found to{" "}
          <a
            className="link-int-page"
            href="https://www.science.org/doi/10.1126/sciimmunol.abe0531"
            target="_blank"
          >
            have “immuno-silent properties,” meaning they are not detectable by
            the immune systems of animals living on land
          </a>
          . The discovery suggests that the Phoenix Islands region may harbor significant resources for immunotherapy, which could aid in recovery from cancer and other diseases. Studying these
          “immuno-silent properties” was the purpose for R/V{" "}
          <span className="falkor-format">Falkor</span>’s{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/discovering-deep-sea-corals-of-the-phoenix-islands-2/"
            target="_blank"
          >
            return and continued research in 2021
          </a>
          . In 2021, the{" "}
          <a
            className="link-int-page"
            href="https://www.theguardian.com/world/2021/nov/16/kiribati-to-open-one-of-worlds-largest-marine-protected-areas-to-commercial-fishing"
            target="_blank"
          >
            Kiribati government announced
          </a>{" "}
          its plans to open up the protected area to commercial fishing, a
          reversal of the 2014 decision, citing economic concerns.
        </p>
      </div>
    </>
  );
}

export default Frontiers;
