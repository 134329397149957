import '../../stylesheets/App.css';
import React, {useRef, useEffect} from 'react';
import BlueCard from '../BlueCard';

function Past4(props) {

    
   return (
    <>
        <section className="panel past4-panel flex">
          <div className="past4-section1"  ref={props.disseminatingRef}  style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past4/past4-bg.png)"}}>
            <div className="title-container mt-general">
              <h1 className="title">Disseminating the Depths</h1>
              <p className="general-text">Schmidt Ocean Institute is committed to engaging the world in oceanographic research through our outreach and communications programs. Over the past ten years, we have established an Artist-at-Sea program, contributed to museum exhibitions, and shown short films worldwide. The following is some of the award-winning work our communications team conducted and assisted with this past year. </p>
            </div>
            <div className="cards-container">
              <BlueCard 
                  title="Artist-at-Sea" 
                  image_url="past4-card1.webp"
                  route="/artists-at-sea"/>
              <BlueCard 
                  title="Awards" 
                  image_url="past4-card2.webp"
                  route="/awards"/>
              <BlueCard 
                  title="Museum Exhibits" 
                  image_url="past4-card3.webp"
                  route="/museum-exhibits"/>
              <BlueCard 
                  title="Press and Footage" 
                  image_url="past4-card4.webp"
                  route="/press-and-footage"/>
            </div>
          </div>
          <div className="past4-section2 bg-settings" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past4/past4-img.webp"}}>
          </div>
        </section>


    </>
  );
}

export default Past4;