import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Stability() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/stability-rec1.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/hunting-bubbles-understanding-plumes-of-seafloor-methane/"
            target="_blank"
          >
            Hunting Bubbles: Understanding Plumes of Seafloor Methane
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientists: Dr. Anna Michel & Dr. Scott Wankel, Woods Hole
          Oceanographic Institution
        </h4>
        <p>
          In 2018, R/V <span className="falkor-format">Falkor</span> traveled to
          the Cascadia Margin to test an underwater bubble capture system using ROV <span className="falkor-format">SuBastian</span>.
          The system collected methane bubbles rising from the seafloor and
          measured their chemical composition and dynamics. The{" "}
          <a
            className="link-int-page"
            href="https://www.frontiersin.org/articles/10.3389/feart.2021.613234/full"
            target="_blank"
          >
            scientists successfully demonstrated
          </a>{" "}
          a technological approach for examining methane transport throughout
          the water column, that combined the use of multibeam sonar, a
          field-portable laser-based spectrometer, and a robotic surface kayak.
          They found elevated methane levels throughout the water column
          including at the surface, suggesting that methane reaches the air-sea
          interface at these shallow seep sites.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/stability-rec2.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/solving_microbial_mysteries_with_autonomous_technology/"
            target="_blank"
          >
            Solving Microbial Mysteries with Autonomous Technology
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Karen Casciotti, Stanford University
        </h4>
        <p>
          In 2018, the science team on board R/V{" "}
          <span className="falkor-format">Falkor</span> investigated how
          chemical cycles and micro-organisms interact in the Eastern Tropical
          North Pacific, a known oxygen-deficient zone in the Ocean. Marine
          oxygen-deficient zones play a large role in the global nitrogen cycle,
          removing fixed oxygen from the Ocean, and yet, are highly productive
          regions due to coastal upwelling. In the lab,{" "}
          <a
            className="link-int-page"
            href="https://www.researchgate.net/journal/Global-Biogeochemical-Cycles-1944-9224/publication/366108999_Coincident_biogenic_nitrite_and_pH_maxima_arise_in_the_upper_anoxic_layer_in_the_Eastern_Tropical_North_Pacific/links/63983f99484e65005b0826b4/Coincident-Biogenic-Nitrite-and-pH-Maxima-Arise-in-the-Upper-Anoxic-Layer-in-the-Eastern-Tropical-North-Pacific.pdf?_sg%5B0%5D=started_experiment_milestone&_sg%5B1%5D=started_experiment_milestone&origin=journalDetail&_rtd=e30%3D"
            target="_blank"
          >
            the science team developed a new method to limit the contribution of
            various biogeochemical processes
          </a>
          , such as anaerobic ammonium oxidation and denitrification, to observe
          nitrate and pH maximums in the upper anoxic layer of the water column.
          They found a nitrite and pH maxima emerged in the oxygen-deficient
          zone’s upper core, distinct features that were observed and analyzed
          due to the high-resolution sampling on board R/V{" "}
          <span className="falkor-format">Falkor</span>, which give insight into
          the dissolved inorganic nitrogen dynamics in the region.
        </p>
      </div>
    </>
  );
}

export default Stability;
