import '../../stylesheets/App.css';
import React, {useRef, useEffect} from 'react';
import VerticalCard from '../VerticalCard';
import { videoSettings } from '../../helpers/helperVideo';

function Past3(props) {

  useEffect(()=>{
    
    videoSettings('#past3-video', '.canvas.canvas-past3' )

    }, []);

   return (
    <>
        <section className="panel past3-panel flex">
          <div className="past3-section1"   ref={props.deepSeaRef} style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past3/past3-bg.png)"}}>
            <div className="title-container mt-general">
              <h1 className="title">The Deep Sea Continuum</h1>
              <p className="general-text">The Schmidt Ocean Institute advances innovation within the Ocean community through its grants, partnerships, and leadership. We view this work as a continuum that pushes the boundaries of Ocean technology, science, and public engagement. </p>
            </div>
            <div className="cards-container">
              <VerticalCard 
                  title="Grants" 
                  image_url="past3-card1.webp"
                  route="/grants"/>
              <VerticalCard 
                  title="Strategic Partnerships" 
                  image_url="past3-card2.webp"
                  route="/strategic-partnerships"/>
              <VerticalCard 
                  title="Leadership" 
                  image_url="past3-card3.webp"
                  route="/leadership"/>
            </div>
          </div>
          <div className="past3-section2">
            <div  className='future2-vid-container past3-vid-container' id="past3-vid-container">
                <video playsInline muted="muted" loop="loop" autoplay="autoplay" poster={process.env.PUBLIC_URL+"/images/past3/past3_poster.webp"} id="past3-video">
                    <source src={process.env.PUBLIC_URL+"/videos/Coral.webm"} type="video/webm"/>
                    <source src={process.env.PUBLIC_URL+"/videos/Coral.mp4"} type="video/mp4"/>
                    <p>Your browser cannot play the provided video file.</p>
                </video>
                <canvas className="canvas canvas-past3" playsInline></canvas>
            </div>    
          </div>
        </section>

    </>
  );
}

export default Past3;