import '../../stylesheets/App.css';
import React, {useRef, useEffect, useState} from 'react';
import StaticCard from '../StaticCard';
import { videoSettings } from '../../helpers/helperVideo';


function Past1(props) {

    useEffect(()=>{
      if(window.innerWidth/window.screen.height>2.1278){
        document.getElementById('past1-video').classList.add('ajustado');
      }else if(window.innerWidth<1200 & (window.screen.height/window.innerWidth<1.4104)){
        document.getElementById('past1-video').classList.add('ajustado');
      }
      videoSettings('#past1-video', '.canvas.canvas-past1');
      

        }, []);



   return (
    <>
        <section className="panel future1-panel past1-panel flex">
            <div ref={props.byTheNumbersRef} className='future2-vid-container bg-settings' id="bythenumbers" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past1/past1_poster.webp)"}}>
                <video playsInline muted="muted" loop="loop" autoplay="autoplay" poster={process.env.PUBLIC_URL+"/images/past1/past1_poster.webp"} id="past1-video">
                    {window.innerWidth>1200?
                      <source src={process.env.PUBLIC_URL+"/videos/Siphonophore.mp4"}  type="video/mp4"/>:
                      <source src={process.env.PUBLIC_URL+"/videos/Siphonophore-mobile.mp4"}  type="video/mp4"/>
                    }
                    <p>Your browser cannot play the provided video file.</p>
                </video>
                <canvas className="canvas canvas-past1" playsInline></canvas> 
                <div className="numbers-degradado"></div>
                <div className="past1-info-container mt-general">
                  <h1 className="title text-white">10 Years of R/V <span className="falkor-format">Falkor</span></h1>
                  <h2 className="subtitle text-white">By The Numbers</h2>
                  <div className="past1-numbers-container">

                      <div className="numbers-row" id="row-1">
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-1.png"}  alt="icon"/>
                          <p className="general-text text-white numbers-text"><span className="text-bold">81</span> expeditions with</p>
                          <p className="general-text text-white numbers-text"><span className="text-bold">1,845</span> days of science</p>
                        </div>
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-2.png"}   alt="icon"/>
                          <p className="general-text text-white numbers-text">Sailed <span className="text-bold">521,000 km</span> -</p>
                          <p className="general-text text-white numbers-text">a distance of <span className="text-bold">13</span> times</p>
                          <p className="general-text text-white numbers-text">around the world</p>
                        </div>
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-3.png"}  alt="icon" />
                          <p className="general-text text-white numbers-text">Hosted <span className="text-bold">1,056</span> scientists, </p>
                          <p className="general-text text-white numbers-text"><span className="text-bold">340</span> students, and <span className="text-bold">43</span> artists</p>
                        </div>
                      </div>

                      <div className="numbers-row" id="row-2">
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-4.png"}  alt="icon" />
                          <p className="general-text text-white numbers-text">Mapped <span className="text-bold">1.34 million km²</span></p>
                          <p className="general-text text-white numbers-text">of seafloor, discovering</p>
                          <p className="general-text text-white numbers-text"><span className="text-bold">17</span> underwater features</p>
                        </div>
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-5.png"}  alt="icon" />
                          <p className="general-text text-white numbers-text">Conducted <span className="text-bold">481</span> underwater dives</p>
                          <p className="general-text text-white numbers-text">with ROV <span className="falkor-format">SuBastian</span>,</p>
                          <p className="general-text text-white numbers-text">totaling <span className="text-bold">2,415</span> hours</p>
                          <p className="general-text text-white numbers-text">of deep sea exploration</p>
                        </div>
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-6.png"}  alt="icon" />
                          <p className="general-text text-white numbers-text">Collected <span className="text-bold">6,194</span> samples resulting in</p>
                          <p className="general-text text-white numbers-text"><span className="text-bold">26</span> new species named and</p>
                          <p className="general-text text-white numbers-text"><span className="text-bold">633</span> TeraBytes of data to advance</p>
                          <p className="general-text text-white numbers-text">scientific understanding</p>
                        </div>
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-7.png"}  alt="icon" />
                          <p className="general-text text-white numbers-text"><span className="text-bold">463,089</span> people connected</p>
                          <p className="general-text text-white numbers-text">via <span className="text-bold">684</span> presentations, podcasts,</p>
                          <p className="general-text text-white numbers-text">Ship-to-Shores</p>
                        </div>
                      </div>

                      <div className="numbers-row" id="row-3">
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-8.png"}  alt="icon" />
                          <p className="general-text text-white numbers-text">Science accomplishments shared</p>
                          <p className="general-text text-white numbers-text">through <span className="text-bold">202</span> scientific publications,</p>
                          <p className="general-text text-white numbers-text">and <span className="text-bold">5,135+</span> articles</p>
                        </div>
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-9.png"}  alt="icon" />
                          <p className="general-text text-white numbers-text">Reached more than <span className="text-bold">470 million</span></p>
                          <p className="general-text text-white numbers-text">on Facebook, Twitter, YouTube,</p>
                          <p className="general-text text-white numbers-text">and Instagram</p>
                        </div>
                         
 
 
                        <div className="numbers-item">
                          <img src={process.env.PUBLIC_URL+"/images/bythenumbers/numbers-10.png"}  alt="icon" />
                          <p className="general-text text-white numbers-text">Exhibited <span className="text-bold">160</span> works</p>
                          <p className="general-text text-white numbers-text">from our Artist-at-Sea collection</p>
                          <p className="general-text text-white numbers-text">at <span className="text-bold">20</span> shows in <span className="text-bold">16</span> cities</p>
                        </div>
                      </div>
                  </div>
              </div>  
            </div>
            <div ref={props.reflectionsRef} className="past1-card-container"  style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/past1/bg-card.png)"}}>
                <StaticCard 
                title="Reflections from the Past" 
                subtitle="A Note From Our Executive Director" 
                description="The past year was centered on transformation as we bid farewell to R/V Falkor in Italy—where she is now R/V Gaia Blu with the Consiglio Nazionale delle Ricerche—and watched R/V Falkor (too) complete its metamorphosis." 
                image_url="past1/past1-card1.webp" 
                route="/directors-note"
                />
            </div>  
        </section>

    </>
  );
}

export default Past1;