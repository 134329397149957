import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function CarouselComponent({imageList}) {

    let carouselSettings = {
        autoPlay: true, // Activar autoplay
        showThumbs: false, // Ocultar miniaturas
        axis: 'horizontal', // Establecer el eje horizontal
        dynamicHeight: true, // Desactivar la altura dinámica
        centerMode:true,
        centerSlidePercentage:70,
        infiniteLoop:true,
        showStatus:false,
        width:'90%',
        emulateTouch:true,
        onClickItem:function(e){
            e.preventDefault();
        }
      };
      if(window.innerWidth<1200){
        carouselSettings = {
          autoPlay: true, // Activar autoplay
          showThumbs: false, // Ocultar miniaturas
          axis: 'horizontal', // Establecer el eje horizontal
          dynamicHeight: true, // Desactivar la altura dinámica
          centerMode:false,
          infiniteLoop:true,
          showStatus:false,
          width:'100%',
          emulateTouch:true,
          onClickItem:function(e){
              e.preventDefault();
          }
        };
      }

  return (
    <Carousel {...carouselSettings}>
      {imageList.map((image, index) => (
        <div className="carousel-img bg-settings" key={index} style={{backgroundImage:"url("+process.env.PUBLIC_URL+image+")"}}>
          
        </div>
      ))}
    </Carousel>
  );
}

export default CarouselComponent;