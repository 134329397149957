
import '../../stylesheets/SubPage.css';
import React, {useRef, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { openSubPage } from '../../helpers/helperNavigation';

function RelatedItem(props) {

    const navigate = useNavigate();

   return (
    <>
    <div className="related-item" onClick={()=>openSubPage(navigate, props.route)}>
            <div className="related-item-img" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/subpages/banners/" + props.image+")"}}/>
            {props.title=="Celebrating a Seaworthy R/V Falkor (too)"?
                <h5 className="general-text">Celebrating a Seaworthy<br/>R/V <span className="falkor-format">Falkor (too)</span></h5>:
                props.title=="R/V Falkor (too)"?
                <h5 className="general-text">R/V <span className="falkor-format">Falkor (too)</span></h5>:
                <h5 className="general-text">{props.title}</h5>
            }
    </div>
        
    </>
  );
}

export default RelatedItem;