import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function FutureFaces(props) {
  console.log(props.actualFace);
  return (
    <>
      <div
        ref={props.faces9Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec9.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Tyler Smith</h3>
          <h4 className="subpage-rec-subtitle">
            2022/23 – Science Communications Intern
          </h4>
          <p>
            Tyler Smith is pursuing a B.S. in Marine Technology at Northwestern
            Michigan College after having been inspired by ROV{" "}
            <span className="falkor-format">SuBastian</span>. Having previously
            earned an M.F.A. in Creative Writing at Louisiana State University,
            his communications background stretches from journalism to video
            production to teaching undergraduate writing courses. After
            graduating, he plans to work with deep-sea robotics systems for
            oceanographic research, writing screenplays, and finding a way to
            bridge the two.
          </p>
        </div>
      </div>

      <div
        ref={props.faces8Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec8.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Bailey Skinner</h3>
          <h4 className="subpage-rec-subtitle">2022 – Data Intern</h4>
          <p>
            Bailey is an Oceanographer at the Naval Oceanographic Office (NAVO)
            in Mississippi, where she collects and processes acoustic data
            aboard naval ships. Her job involves organizing decades of archived
            acoustic data to be more accessible across NAVO. Her experience at
            SOI has been an asset in her new role, especially in applying her
            increased programming knowledge to data projects.
          </p>
        </div>
      </div>

      <div
        ref={props.faces7Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec7.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Brandon Chan</h3>
          <h4 className="subpage-rec-subtitle">
            2021/22 – Science Communications Intern
          </h4>
          <p>
            As an SOI intern, Brandon Chan worked on his Ph.D. at UCLA, studying
            the impacts of climate change on phytoplankton physiology and
            ecology. Since then, his lab has moved to the University of
            Connecticut, where Brandon is continuing his Ph.D. and getting used
            to New England winters. Brandon was recently accepted into the
            National Science Foundation’s Graduate Research Fellowship Program.
          </p>
        </div>
      </div>

      <div
        ref={props.faces6Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec6.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Brittany Washington</h3>
          <h4 className="subpage-rec-subtitle">
            2021 – Science Communications Intern
          </h4>
          <p>
            Brittany is working toward her Master of Science at Brooklyn College
            and plans to start a Ph.D. in biogeochemistry this fall. In addition
            to her research, she is a freelance journalist and multimedia
            producer and has contributed to projects with{" "}
            <span className="falkor-format">The New Yorker</span>, A24, HBO,
            REI, and The New York Academy of Sciences. She plans to continue her
            work across communicating science during her Ph.D. studies.
          </p>
        </div>
      </div>

      <div
        ref={props.faces5Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec5.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Amara Davis</h3>
          <h4 className="subpage-rec-subtitle">
            2020/21 – Science Communications Intern
          </h4>
          <p>
            After completing her internship, Amara received her Master of
            Science from Savannah State University, with her thesis on the
            Bahama lobster fishery. She was selected as a NOAA Knauss Fellow in
            2021 and conducted her fellowship with Sea Grant in Washington, DC.
            She is now the Communications and Outreach Coordinator at Sea Grant.
          </p>
        </div>
      </div>

      <div
        ref={props.faces4Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec4.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Shannon McClish</h3>
          <h4 className="subpage-rec-subtitle">
            2019/20 – Science Communications Intern
          </h4>
          <p>
            Shannon is continuing her Ph.D. at the University of Hawai’i and
            working on understanding the strength and variability of the
            biological carbon pump in the seasonal sea ice zone of the Southern
            Ocean. In addition to her research activities, she has been a guest
            speaker in undergraduate and elementary school classes.
          </p>
        </div>
      </div>

      <div
        ref={props.faces3Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec3.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Katherine Herries</h3>
          <h4 className="subpage-rec-subtitle">
            2018/19 – Science Communications Intern
          </h4>
          <p>
            Katherine currently works as a Water Pollution Inspector for the
            Missouri Department of Natural Resources. She communicates with the
            public daily, explaining state laws and regulations for keeping
            waterways clean for recreational and industrial uses. After her time
            at SOI and prior to her work in Missouri, Katherine worked for the
            U.S. Environmental Protection Agency (EPA), informing senior-level
            scientists and the EPA Director of new guidelines and innovative
            research on human health and environmental risk assessment.
          </p>
        </div>
      </div>

      <div
        ref={props.faces2Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec2.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Victoria Sindorf</h3>
          <h4 className="subpage-rec-subtitle">
            2017/18 – Science Communications Intern
          </h4>
          <p>
            After Victoria’s internship, she worked as the Education Manager for
            a coastal property of The Trustees of Reservations—the oldest and
            largest land conservation organization in Massachusetts. Using
            skills she gained during her internship, Victoria created
            educational programming about the coastal environment—communicating
            through social media channels, maintaining the website, and creating
            interpretive materials. She recently moved to Iceland.
          </p>
        </div>
      </div>

      <div
        ref={props.faces1Ref}
        className="subpage-recuadro general-text flex-rec"
      >
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/future-faces-rec1.webp"
          }
          alt="img"
        />
        <div className="text-container">
          <h3 className="subpage-rec-title mt-0">Holly Lauridsen</h3>
          <h4 className="subpage-rec-subtitle">
            2016/17 – Science Communications Intern
          </h4>
          <p>
            Communicating scientific ideas is a significant part of Holly’s
            current role in the medical device industry as a Product Specialist
            for W.L. Gore & Associates. She focuses on new disease research at
            the company, where her role involves educating leadership about the
            state of research, pathways for success in emerging disease studies,
            and how to connect research to new strategies.
          </p>
        </div>
      </div>
    </>
  );
}

export default FutureFaces;
