import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function WordsFromOurFounders() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <figure className="float">
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/subpages/internas/words-founders-rec1.webp"
            }
            alt="img"
          />
          <figcaption>
            Eric and Wendy Schmidt, Founders, Schmidt Ocean Institute
          </figcaption>
        </figure>

        <p>
          For all of human history, the horizon has captured our attention,
          daring us to venture out to sea to find what might lie beyond or
          below. The year 2023 is no different—except those who venture now have
          a bigger ship.
        </p>
        <p>
          R/V <span className="falkor-format">Falkor (too)</span> will help us
          advance what has been Schmidt Ocean Institute’s aim from our earliest
          days: to seek and share knowledge and understanding of our Ocean.
          Scientists worldwide, including those from communities and countries
          too long underrepresented in marine research, have joined our
          expeditions and shared remarkable findings that exceeded all our
          ambitions. We have no doubt that the scientific community will do it
          again in this Ocean Decade, a crucial historical period for exploring
          and understanding the source of all life. Indeed, the research
          conducted aboard <span className="falkor-format">Falkor (too)</span>{" "}
          has a purpose: to help us make better decisions for our future
          survival on the Earth.
        </p>
        <p>
          Schmidt Ocean Institute’s commitment to sharing knowledge, partnering
          across continents, and bringing understanding back ashore will carry
          forward to our new ship. R/V{" "}
          <span className="falkor-format">Falkor (too)</span>’s scientific and
          communications abilities are unrivaled, and we can’t wait to watch,
          alongside all of you, as the seekers aboard{" "}
          <span className="falkor-format">Falkor (too)</span> peer beyond the
          horizon of what we know today.
        </p>
      </div>
    </>
  );
}

export default WordsFromOurFounders;
