import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Strategic() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float with-border"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/strategic-rec1.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">National Geographic Society</h3>

        <p>
          In June, SOI and the National Geographic Society{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/soi-ngs-partner-to-advance-diversity/"
            target="_blank"
          >
            announced a formal partnership
          </a>{" "}
          to advance diversity and equity in Ocean exploration at the UN Ocean
          Conference in Lisbon, Portugal. The partnership will provide seagoing
          opportunities aboard R/V{" "}
          <span className="falkor-format">Falkor (too)</span> to
          underrepresented, historically marginalized participants, local
          scientists, and National Geographic Explorers, who will begin
          occupying berths in 2023. The partnership will amplify the voices of
          underrepresented marine scientists and storytellers and provide
          leadership opportunities in Ocean science.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/strategic-rec2.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">NOAA Ocean Exploration</h3>

        <p>
          SOI continues to collaborate with NOAA Ocean Exploration and Ocean
          Exploration Trust on the{" "}
          <a
            className="link-int-page"
            href="https://deepoceaneducation.org/"
            target="_blank"
          >
            Deep Ocean Education Project
          </a>{" "}
          (DOEP) website. The website merges each organization's most engaging
          content for educators and community groups to build lesson plans,
          learn, and explore in one space. In 2022, the team focused on growing
          the library and updating available resources, such as expanding the
          resources and materials offered in Spanish. Over 500 people have
          created accounts with DOEP since the website’s launch.
        </p>

        <p>
          In January, the three organizations met virtually alongside a group of
          traditional knowledge holders, educators, and community leaders in the
          Pacific Islands to gather advice on how to best work with and serve
          the local communities when research vessels are operating in Pacific
          waters. Their recommendations for respectfully working and engaging in
          project co-design in the Pacific Islands are compiled in{" "}
          <a
            className="link-int-page"
            href="https://deepoceaneducation.org/wp-content/uploads/2022/07/InKupesWake_MASTER_Digital.pdf"
            target="_blank"
          >
            <span className="falkor-format">
              Kupe’s Wake Recommendations Report
            </span>
          </a>
          .
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/strategic-rec3.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">Ocean Rising and Nekton</h3>

        <p>
          <a
            className="link-int-page"
            href="https://schmidtocean.org/ocean-rising/"
            target="_blank"
          >
            Ocean Rising
          </a>{" "}
          is an ongoing SOI initiative with Nekton to inspire the public through
          popular culture including the arts, broadcast media, social media,
          sports, gaming, and business. This year, two workshops were held in
          London to brainstorm with industry experts on how to best incorporate
          the Ocean into fashion and music. Each of these sectors is critical in
          defining popular culture and can be influential in bringing the Ocean
          to a general public audience. The fashion-focused workshop included
          breakout sessions with representatives from the Apparel Impact
          Institute, The R Collective, Conde Nast, and others. Climate and music
          organizations, such as Reverb, Julie’s Bicycle, Earth Percent, and
          Music Declares Emergency, and artists and their management, labels,
          and festival organizers attended the music industry workshop. SOI,
          Nekton, and members of both the fashion and music communities will
          continue to work together through 2023 to raise awareness about the
          Ocean, implementing the workshop suggestions.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/strategic-rec4.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">Seabed 2030</h3>

        <p>
          SOI has partnered with The Nippon Foundation - GEBCO Seabed 2030
          Project since 2019 and continues to support their goal of a
          high-resolution map of the seafloor with the addition of the 19-meter
          gondola and full-ocean depth sonars on R/V{" "}
          <span className="falkor-format">Falkor (too)</span>. At the UN Ocean
          Conference in Lisbon, Portugal, Seabed 2030 announced that 23.4% of
          the seafloor had been mapped. SOI’s Executive Director, Dr. Jyotika
          Virmani, was also featured in one of Seabed 2030’s{" "}
          <a
            className="link-int-page"
            href="https://youtu.be/nEFA_hwCpdo"
            target="_blank"
          >
            partnership videos
          </a>{" "}
          that was released last year.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/strategic-rec5.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">
          Supporting Students in Marine Technology with MTS/IEEE-OES
        </h3>

        <p>
          Marine Technology Society (MTS), the Institute of Electrical and
          Electronics Engineers Oceanic Engineering Society (IEEE-OES), and SOI
          continued to partner in 2022. The three organizations worked to
          support students and early career marine technology professionals
          through the International{" "}
          <a
            className="link-int-page"
            href="https://www.oceansstudentpostercompetition.org/"
            target="_blank"
          >
            Student Poster Competition
          </a>{" "}
          awards at the{" "}
          <a
            className="link-int-page"
            href="https://chennai22.oceansconference.org/"
            target="_blank"
          >
            MTS/IEEE Oceans 2022 Chennai Conference
          </a>
          . SOI’s financial contribution provides a small monetary prize to the
          student poster competition winners. Yang Weng, from the University of
          Tokyo, Japan, won first place for the poster{" "}
          <span className="falkor-format">
            Sim-to-Real Transfer for Underwater Wireless Optical Communication
            Alignment Policy between AUVs
          </span>
          . Zhiding Yang, from Memorial University of Newfoundland, Canada, won
          second place for the poster{" "}
          <span className="falkor-format">
            A Temporal Convolutional Network for Wave Height Estimation from
            X-band Radar Data
          </span>
          , and Fabricio Bozzi, from University of Algarve, Portugal, won third
          place for the poster{" "}
          <span className="falkor-format">
            Vector Hydrophone Passive Time Reversal for Underwater Acoustic
            Communications
          </span>
          .
        </p>

        <p>
          As part of this partnership, SOI has committed to sponsoring
          MTS-Seattle Chapter events. At one of these events, SOI Senior
          Director of Operations, Eric King gave a presentation on the refit of
          R/V <span className="falkor-format">Falkor (too)</span>.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/strategic-rec6.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">UNESCO/ The Ocean Decade </h3>

        <p>
          SOI continued to participate in the{" "}
          <a
            className="link-int-page"
            href="https://oceandecade.org/"
            target="_blank"
          >
            UN Decade of Ocean Science for Sustainable Development
          </a>{" "}
          in several ways, including attending the{" "}
          <a
            className="link-int-page"
            href="https://oceandecade.org/news/m6f-and-unesco-ioc-convene-second-foundations-dialogue-to-mobilise-philanthropic-community/"
            target="_blank"
          >
            2nd Foundations Dialogue in Morocco
          </a>
          , which resulted in the{" "}
          <a
            className="link-int-page"
            href="https://www.livingoceansfoundation.org/wp-content/uploads/2022/06/Foundations-Dialogue-Bouknadel-Statement.pdf"
            target="_blank"
          >
            Bouknadel Statement
          </a>
          , of which SOI is a signatory, and an Ocean Funders Collaborative of
          which SOI is a member. The statement calls for foundations working
          with the Decade to focus on co-design, communications, and capacity
          development.
        </p>

        <p>
          In June, SOI staff attended the{" "}
          <a
            className="link-int-page"
            href="https://www.un.org/en/conferences/ocean2022"
            target="_blank"
          >
            UN Ocean Conference
          </a>{" "}
          in Lisbon, Portugal, where the theme was{" "}
          <span className="falkor-format">
            Scaling Up Ocean Action Based on Science and Innovation for the
            Implementation of Goal 14: Stocktaking, Partnerships, and Solutions
          </span>
          . SOI staff spoke at and participated in 15 events, including the{" "}
          <a
            className="link-int-page"
            href="https://media.un.org/en/asset/k1v/k1vi5rg981"
            target="_blank"
          >
            High-Level Ocean Decade Alliance Meeting
          </a>
          , the{" "}
          <a
            className="link-int-page"
            href="https://www.youtube.com/watch?v=pB-3X2v3Qcc"
            target="_blank"
          >
            Ocean Decade Forum
          </a>
          , and several youth-focused events, like a panel on Ocean and pop
          culture at the{" "}
          <a
            className="link-int-page"
            href="https://www.soalliance.org/2022-ocean-youth-and-innovation-forum"
            target="_blank"
          >
            Youth and Innovation Forum
          </a>
          . During the conference, SOI exhibited 20 pieces from our
          Artist-at-Sea collection at the{" "}
          <a
            className="link-int-page"
            href="https://www.cienciaviva.pt/en/"
            target="_blank"
          >
            Ciencia Viva
          </a>{" "}
          and{" "}
          <a
            className="link-int-page"
            href="https://gulbenkian.pt/museu/en/"
            target="_blank"
          >
            Gulbenkian Museum
          </a>
          .
        </p>

        <p>
          Additionally, SOI’s Executive Director, Jyotika Virmani, continued to
          co-chair the Decade Ocean Technology and Innovation Working Group. SOI
          Director of Communications and Engagement Strategy, Carlie Wiener,
          also continued to co-chair the Decade Communications Advisory Group.
        </p>
      </div>
    </>
  );
}

export default Strategic;
