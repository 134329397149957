import '../../../stylesheets/SubPage.css';
import React, {useRef, useEffect} from 'react';

function Seafloor() {


   return (
    <>
    <div className="subpage-recuadro general-text">
            <img className="float" src={process.env.PUBLIC_URL+"/images/subpages/internas/seafloor-rec1.webp"} alt="img"/>
            <h3 className="subpage-rec-title mt-0"><a className="link-int-page" href="https://schmidtocean.org/cruise/leg-two-papahanaumokuakea-monument/" target="_blank">Papahānaumokuākea Monument</a></h3>
            <h4 className="subpage-rec-subtitle">Chief Scientist: Dr. Christopher Kelley, University of Hawai’i at Mānoa</h4>
            <p>In 2014, R/V <span className="falkor-format">Falkor</span> spent over 70 days mapping the Papahānaumokuākea Marine National Monument in the Hawai’i Archipelago. <a className="link-int-page" href="https://eos.org/science-updates/new-insights-from-seafloor-mapping-of-a-hawaiian-marine-monument" target="_blank">The data resulted in complete high-resolution maps for all nineteen seamounts in the Monument</a>. The maps revealed a new 4000-meter tall seamount, named Schmidt Seamount, in honor of the support the science team recieved from SOI. Additionally, the data uncovered that <a className="link-int-page" href="https://www.sciencedirect.com/science/article/pii/S0012821X20302399?dgcid=author" target="_blank">Pūhāhonu is the largest shield volcano on Earth</a>. The resulting seafloor map was shared with the National Oceanic and Atmospheric Administration’s (NOAA) Ocean Exploration, whose team used the map to predict the locations of the highest concentration of biodiversity inside the Monument. A subsequent expedition with the NOAA vessel, <span className="falkor-format">Okeanos Explorer</span>, discovered <a className="link-int-page" href="https://zookeys.pensoft.net/articles.php?id=11500" target="_blank"><span className="falkor-format">Tosanoides obama</span></a>, a tropical fish named for United States President Barack Obama. All of these new discoveries and data (collected by both SOI and NOAA) ultimately led to the expansion of the Monument in 2016.</p>
    </div>
    <div className="subpage-recuadro general-text">
            <img className="float" src={process.env.PUBLIC_URL+"/images/subpages/internas/seafloor-rec2.webp"} alt="img"/>
            <h3 className="subpage-rec-title mt-0"><a className="link-int-page" href="https://schmidtocean.org/cruise/northern-depths-of-the-great-barrier-reef/" target="_blank">Northern Depths of the Great Barrier Reef</a></h3>
            <h4 className="subpage-rec-subtitle">Chief Scientist: Dr. Robin Beaman, James Cook University</h4>
            <p>In 2020, at the height of the global pandemic, R/V <span className="falkor-format">Falkor</span> spent 48 days exploring and extensively mapping the northern region of the Great Barrier Reef. During the expedition, systematic mapping of the region led to the discovery of a 500-meter-tall detached reef, the <a className="link-int-page" href="https://schmidtocean.org/australian-scientists-discover-500-meter-tall-coral-reef-in-the-great-barrier-reef-first-to-be-discovered-in-over-120-years/" target="_blank">first to be located in this part of the world in over 120 years</a>. ROV <span className="falkor-format">SuBastian</span> then dove on the newly unveiled feature to explore the reef from the base to the top. The <a className="link-int-page" href="https://www.youtube.com/watch?v=L_Ee7JV5oSI" target="_blank">dive was live-streamed</a> and has been viewed over 450,000 times in the past two years. Discovering such a sizable structure on a well-known underwater feature, such as the Great Barrier Reef, reveals how much more we still don’t know about what lies in the Ocean.</p>
    </div>
        
    </>
  );
}

export default Seafloor;