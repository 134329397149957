import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Leadership() {
  return (
    <>
      <div className="subpage-recuadro no-min-height general-text">
        <p>
          SOI staff participated in 76 events throughout the year and served as
          panelists, moderators, presenters, and interviewees in podcasts. In
          total, SOI staff reached a global audience of over 15,000. Events
          included the{" "}
          <a
            className="link-int-page"
            href="https://col.ucar.edu/2022-national-ocean-exploration-forum"
            target="_blank"
          >
            National Ocean Exploration Forum
          </a>{" "}
          in Austin, Texas, which sought to identify and share national
          exploration priorities for the United States over the next decade. SOI
          staff served on the planning committee for the Forum, moderated
          breakout sessions, and synthesized discussions for the{" "}
          <a
            className="link-int-page"
            href="https://col.ucar.edu/2022-national-ocean-exploration-forum"
            target="_blank"
          >
            Workshop Report
          </a>
          . SOI staff served on the planning committee for{" "}
          <a
            className="link-int-page"
            href="https://marinesanctuary.org/chow-2022/"
            target="_blank"
          >
            Capitol Hill Ocean Week
          </a>{" "}
          in Washington, D.C., and several others participated in the event.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/leadership-rec2.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">
          The Ditchley Foundation and Open Data Sharing
        </h3>

        <p>
          One of SOI’s key tenets is the open sharing of data and information.
          In October, SOI in partnership with The Ditchley Foundation, hosted a
          workshop,{" "}
          <span className="falkor-format">
            Credit where Credit is Due: Authorship of Open Ocean Data
          </span>
          , at Ditchley Park in England. The workshop gathered attendees from
          across Europe with various perspectives on the data pipeline,
          including publishing, academic research, data sharing, and scientific
          funding, to discuss how to best recognize and reward the dissemination
          of acquired data and knowledge. Participants identified barriers and
          discussed implementable solutions for making data sharing easier,
          acknowledging data sharing as a form of productivity, and giving
          proper credit to those that contribute. Their recommendations for
          cross-sectoral and sector-specific solutions to encouraging open data
          sharing are compiled in{" "}
          <a
            className="link-int-page"
            href="https://zenodo.org/record/7713180#.ZBstQ3bMKM-"
            target="_blank"
          >
            <span className="falkor-format">
              Credit where Credit is Due: Authorship of Open Ocean Data
            </span>
          </a>
          .
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float with-border"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/leadership-rec3.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">Pink Flamingo Society (PFS)</h3>

        <p>
          PFS promotes the safe, efficient, and environmentally responsible
          operation of research vessels primarily funded through philanthropy in
          support of the global oceanographic scientific research community. SOI
          first convened the group in 2019, and continues to engage with the
          fourteen member organizations. In 2022, the group met at the Monterey
          Bay Aquarium Research Institute (MBARI) in Moss Landing, California to
          discuss opportunities for collaboration in 2023 and beyond. During the
          meeting, SOI staff gave presentations related to data sharing, DEI
          initiatives, participation in the UN Ocean Decade, and moderated
          discussions focused on the challenges and opportunities facing the
          group of philanthropic research vessel operators. The current Chair is
          Vincent Pieribone, OceanX, and co-Vice Chairs are Chris Scholin,
          MBARI, and Romain Trouble, Tara Foundation. SOI’s Senior Director of
          Operations, Eric King, serves in the Leadership Group as the immediate
          past chair, and Carlie Wiener chairs the Communications Working Group.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float with-border"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/leadership-rec4.webp"
          }
          alt="img"
        />

        <h3 className="subpage-rec-title mt-0">
          Scientific Ocean Vehicle Alliance (SOVA)
        </h3>

        <p>
          The Scientific Ocean Vehicle Alliance (SOVA), formed in 2020 by SOI’s
          Lead Mechanical Engineer, Jason Williams, is a community of operators,
          managers, engineers, and technicians involved in the development and
          operations of scientific ocean vehicles. The group shares knowledge
          and lessons-learned to optimize the overall operations of marine
          science vehicles, increase efficiencies, and standardize operational
          procedures for conducting ocean science across organizations. In
          addition to the annual meeting in April, the alliance gathered two
          more times virtually to discuss ROV umbilical management, staffing,
          and internship programs. Members also have access to a private
          discussion forum to exchange ideas in between formal meetings. SOVA’s
          plans for 2023 include meeting in person for the first time since the
          formation of the alliance (exactly three years after SOVA formed),
          hosting topic-focused workshops, and expansion to include the
          international scientific vehicle community.
        </p>
      </div>
    </>
  );
}

export default Leadership;
