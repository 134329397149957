import '../stylesheets/Home.css';
import Center from './homeSections/Center';
import Future1 from './homeSections/Future1';
import Future2 from './homeSections/Future2';
import Past1 from './homeSections/Past1';
import Past2 from './homeSections/Past2';
import Past3 from './homeSections/Past3';
import Past4 from './homeSections/Past4';
import Loader from './Loader';
import React, {useRef, useEffect, useState} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
//import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { goToNextSection, goToPrevSection } from '../helpers/helperNavigation';
gsap.registerPlugin(ScrollTrigger);
//gsap.registerPlugin(ScrollToPlugin);



function Home(props) {
    let [loading, setLoading] = useState(true);
    
    
    const imgRef = useRef();
    window.addEventListener("scroll", ()=>{
      
        console.log("y: "+window.scrollY)
        let flechaLeft = document.querySelector('.prev-nav-btn');
        if(window.scrollY<10){
          flechaLeft.style.display='none';
        }else{
          flechaLeft.style.display='block';
        }
        let flechaRight = document.querySelector('.next-nav-btn');
        if(window.scrollY>=window.innerWidth*6.9){
          flechaRight.style.display='none';
        }else{
          flechaRight.style.display='block';
        }
        /*console.log("x: "+window.scrollX)*/
        /*
        console.log("Ancho del contenedor:"+document.querySelector(".container").offsetWidth)
        console.log("Ancho del contenedor:"+document.querySelector(".container").clientWidth)
    */
      })
    //funcion para transformar el scroll horizontal en vertical
    document.addEventListener('keydown', function(event){
      var codigo = event.which || event.keyCode;
      /*
      console.log('tecla presionada');
      console.log(event);
      console.log(codigo);*/
      if(codigo == 37 || (codigo == 40 && window.location.pathname === "/")){
        event.preventDefault();
        window.scroll(0,window.scrollY-20);
      }else if(codigo == 39 || (codigo == 38 && window.location.pathname === "/")){
        event.preventDefault();
        window.scroll(0,window.scrollY+20);
      }

    })
    


   useEffect(()=>{
    setTimeout(()=>setLoading(false), 5000);
    /*setting 100vh*/
    // If less than most tablets, set CSS var to window height.
    let value = "100vh"

    // If window size is iPad or smaller, then use JS to set screen height.
    if (window.innerWidth && window.innerWidth <= 1024) {
        value = `${window.innerHeight}px`
    }
    document.documentElement.style.setProperty("--real100vh", value)
    /*animacion inicial*/
    let falkors = [document.querySelector(".falkor-img"),document.querySelector(".falkor-too-img")];
    falkors[1].style.left = '50vw';
    let widthFalkor = window.innerWidth<1600&&window.innerHeight<990 ? 250 : 300;
    let widthFalkorToo = window.innerWidth<1600&&window.innerHeight<990 ? 300 : 350;
  
    
    gsap.to(falkors[0], { 
      delay:5,
      width:widthFalkor,
      duration:3
    })
    
    gsap.to(falkors[1], { 
      delay:5,
      width:widthFalkorToo,
      duration:3
    })
    let mousesContainer = document.querySelector(".home-mouse-container");
    gsap.timeline().to(mousesContainer, { 
      gap:80,
      duration:2, 
      delay:5
    }).to(mousesContainer, { 
      gap:70,
      duration:1
    })
    let logo = document.querySelector(".home-logo");
    gsap.timeline().to(logo, {
      rotate:30,
      duration:2,
      delay:5
    }).to(logo, {
      rotate:20,
      duration:1
    })

    /*animaciones scroll general*/
    let sections = gsap.utils.toArray(".panel");

    let scrollTween = gsap.to(sections, {
      xPercent: -100 * (sections.length-1),
      //-100 * (sections.length-1) - 13
      ease: "none",
      scrollTrigger: {
        trigger: ".container",
        pin: true,
        scrub: 1,
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + document.querySelector(".container").offsetWidth
      }

    
    });

   /*animacion scroll de cada seccion*/
    /*center section*/
    /*
   const tl = gsap.timeline({
    scrollTrigger: {
      trigger: sections[4],
      containerAnimation: scrollTween,
      start: 'left 10%',
      end: 'right 10%',
      scrub:1
    }
  });
  tl.to(logo, {
    rotate:30
  }).to(logo, {
    rotate:20
  })
  const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: sections[4],
      containerAnimation: scrollTween,
      start: 'left 50%',
      end: 'right 50%',
      scrub:1, 
      duration:2,
    }
  });
  for(let i=0;i<4;i++){
    tl2.to(mousesContainer, { 
      gap:100,
    }).to(mousesContainer, { 
      gap:50,
    })
  }
  */
  /*future1 section1*/
  /*
  let cardFuture1section1Text = document.querySelector(".future1-section1 .static-card-title-container");
  let cardFuture1section1Image = document.querySelector('.future1-section1 .static-card-img');


  const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[5],
        containerAnimation: scrollTween,
        start: 'left 80%',
        end: 'right 110%',
        scrub:1,
        duration:5
      }
    }).to(cardFuture1section1Image, {
      delay:-3,
      opacity:1,
      duration:2
    }).to(cardFuture1section1Text, {
      delay:-3,
      opacity:1,
      duration:2
    })*/
    /*future1 section2*/
    /*
    let future1Section2titles= [document.querySelector(".future1-section2 .future1-title"), document.querySelector(".future1-section2 .future1-subtitle")];
    let future1Section2Description = document.querySelector(".future1-panel .future1-description")
    let future1Section2Cards = document.querySelectorAll(".cards-future1-container .card-container")
    let textHeight = window.innerWidth<1600&&window.innerHeight<990?110:140;
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: '.future1-section2',
        containerAnimation: scrollTween,
        start: 'left 60%',
        end: 'right 80%',
        scrub:1,
        duration:8, 
      }
    }).to(future1Section2titles, {
      opacity:1,
      duration:2,
      stagger: {
        amount:0
      }
    }).to(future1Section2Description, {
      delay:0,
      height:textHeight,
      duration:1
    }).to(future1Section2Cards, {
      opacity:1,
      duration:6,
      stagger: {
      each: 1, // tiempo de retraso entre cada animación, en segundos
      from: "start" // comenzar la animación desde el inicio del arreglo
  }
    })*/

    /*future2*/
    /*
    let cardFuture2Description = document.querySelectorAll(".future2-panel .future2-card-container .static-card-title-container");
    let cardFuture2Image = document.querySelectorAll('.future2-card-container .static-card-img');

    const tl5 = gsap.timeline({
        scrollTrigger: {
          trigger: sections[6],
          containerAnimation: scrollTween,
          start: 'left 70%',
          end: 'right 100%',
          scrub:1,
          duration:5
        }
      }).to(cardFuture2Description[0], {
        delay:-1,
        opacity:1,
        duration:2
      }).to(cardFuture2Image[0], {
        delay:-2,
        opacity:1,
        duration:2
      }).to(cardFuture2Description[1], {
        delay:3,
        opacity:1,
        duration:2
      }).to(cardFuture2Image[1], {
        delay:-2,
        opacity:1,
        duration:2
      })
*/

    /*past1*/
    /*
    let past1CardDescription = document.querySelector(".past1-card-container .static-card-title-container");
    let past1CardImage = document.querySelector(".past1-card-container .static-card-img");
    let byTheNumbersSection = document.querySelector(".past1-info-container");
    const tl7 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[3],
        containerAnimation: scrollTween,
        start: 'left 0%',
        end: 'right 30%',
        scrub:1,
        duration:5,
      }
    }).to(byTheNumbersSection, {
      opacity:0,
      duration:2
    }).to(past1CardDescription, {
      opacity:0,
      duration:1,
      delay:-1
    }).to(past1CardImage, {
      opacity:0,
      duration:1,
      delay:-1
    })
    */
    /*past2*/
    /*
    let past2Titles = [document.querySelector(".past2-panel .title-container h1"), document.querySelector(".past2-panel .title-container h2")];
    let past2Items = document.querySelectorAll('.past2-flex-container .past2-item');

    const tl8 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[2],
        containerAnimation: scrollTween,
        start: 'left 0%',
        end: 'right 80%',
        scrub:1,
      }
    }).to(past2Items, {
      opacity:0,
      duration:6,
      stagger: {
      each: 1, // tiempo de retraso entre cada animación, en segundos
      from: "end" // comenzar la animación desde el inicio del arreglo
  }
    }).to(past2Titles[1], {
      opacity:0,
      duration:1,
    }).to(past2Titles[0], {
      opacity:0,
      duration:1,
    })*/

    /*past3*/
    /*
    let past3Titles = [document.querySelector(".past3-panel .title-container h1"), document.querySelector(".past3-panel .title-container h2")];
    let past3Description = document.querySelectorAll('.past3-panel .title-container .general-text');
    let past3Items = document.querySelectorAll('.past3-panel .cards-container .vertical-card');

    const tl9 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[1],
        containerAnimation: scrollTween,
        start: 'left 0%',
        end: 'right 70%',
        scrub:1,
      }
    }).to(past3Items, {
      opacity:0,
      duration:6,
      stagger: {
      each: 1, // tiempo de retraso entre cada animación, en segundos
      from: "end" // comenzar la animación desde el inicio del arreglo
  }
    }).to(past3Description, {
      opacity:0,
      duration:1,
    }).to(past3Titles[1], {
      opacity:0,
      duration:1,
    }).to(past3Titles[0], {
      opacity:0,
      duration:1,
    })*/

    /*past4*/
    /*
    let past4Titles = [document.querySelector(".past4-panel .title-container h1"), document.querySelector(".past4-panel .title-container h2")];
    let past4Description = document.querySelectorAll('.past4-panel .title-container .general-text');
    let past4Items = document.querySelectorAll('.past4-panel .cards-container .card-container');

    const tl10 = gsap.timeline({
      scrollTrigger: {
        trigger: sections[0],
        containerAnimation: scrollTween,
        start: 'left 0%',
        end: 'right 70%',
        scrub:1,
      }
    }).to(past4Items, {
      opacity:0,
      duration:6,
      stagger: {
      each: 1, // tiempo de retraso entre cada animación, en segundos
      from: "end" // comenzar la animación desde el inicio del arreglo
  }
    }).to(past4Description, {
      opacity:0,
      duration:1,
    }).to(past4Titles[1], {
      opacity:0,
      duration:1,
    }).to(past4Titles[0], {
      opacity:0,
      duration:1,
    })*/
  
    
  //ubico la pantalla en la mitad del timeline
  let puntoMedio = (document.querySelector(".container").clientWidth*4/7)+(window.innerWidth*0.667) /*(document.querySelector(".container").clientWidth/2)+(window.innerWidth*1.17)*/
  let staticFalkors = [document.querySelector(".falkor-img-static"), document.querySelector(".falkor-too-img-static")]
  window.scroll(0,puntoMedio);
  //evento scroll para manejar la posicion de los barcos
  window.addEventListener("scroll", ()=> {
    if(window.scrollY>(puntoMedio+20)){
      falkors[0].classList.add("hiddenFalkor");
      staticFalkors[0].classList.remove("hiddenFalkor");
      falkors[1].classList.remove("hiddenFalkor");
      staticFalkors[1].classList.add("hiddenFalkor");
    }else if(window.scrollY<(puntoMedio-20)){
      falkors[0].classList.remove("hiddenFalkor");
      staticFalkors[0].classList.add("hiddenFalkor");
      falkors[1].classList.add("hiddenFalkor");
      staticFalkors[1].classList.remove("hiddenFalkor");
    }

  })

    }, []);

   

   return (
    <div>
      {loading?<Loader/>:''}
      <div className="container">
            <Past4 disseminatingRef={props.disseminatingRef}/>
            <Past3 deepSeaRef={props.deepSeaRef}/>
            <Past2   anthologyRef={props.anthologyRef} />
            <Past1 byTheNumbersRef={props.byTheNumbersRef} reflectionsRef={props.reflectionsRef}/>
            <Center centerRef = {props.centerRef}/>
            <Future1  soiRef={props.soiRef} visionsRef={props.visionsRef}/>
            <Future2 futureRef = {props.futureRef}/>
            
        </div>
          <div className="falkor-img" style={{backgroundImage:"url("+process.env.PUBLIC_URL+"/images/home/falkor.png"+")"}}/>
          <div className="falkor-too-img" style={{backgroundImage: "url("+process.env.PUBLIC_URL+"/images/home/falkor_too.png"+")"}}/>
          <img onClick={()=>goToPrevSection(props)} className="home-nav-btn prev-nav-btn" src={process.env.PUBLIC_URL+"/images/arrows/arrow-left-nav.png"}  alt="prev-arrow"/>
          <img onClick={()=>goToNextSection(props)} className="home-nav-btn next-nav-btn" src={process.env.PUBLIC_URL+"/images/arrows/arrow-right-nav.png"} alt="next-arrow"/>

    </div>
  );
}

export default Home;