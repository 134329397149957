import "../stylesheets/App.css";
import "../stylesheets/Menu.css";
import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import {
  goHomeFromMenu,
  goToSubPageFromMenu,
  scrollToSection,
  scrollToFacesSection,
} from "../helpers/helperNavigation";

function Menu(props) {
  //0 : ninguno
  //1 : A Scientific Anthology
  //2 : The Deep Sea Continuum
  //3 : Disseminating the Depths
  //4 : SOI Remastered
  //5 : The Future Faces of Ocean Science
  let [submenuActivo, setSubmenuActivo] = useState(0);
  const navigate = useNavigate();

  function updateSubmenu(event, submenuItem) {
    if (submenuActivo == submenuItem) {
      setSubmenuActivo(0);
      event.target.style.transform = "rotate(90deg)";
    } else {
      if (submenuActivo != 0) {
        document.getElementById("arrow-" + submenuActivo).style.transform =
          "rotate(90deg)";
      }
      setSubmenuActivo(submenuItem);
      event.target.style.transform = "rotate(-90deg)";
    }
  }

  return (
    <div className="menu-full-container">
      <nav className="menu-nav-container">
        <ul className="principal-menu">
          <li className="menu-item has-children">
            <div className="menu-title">
              <h1>Reflections from the Past</h1>
              <img
                className="menu-falkor"
                src={process.env.PUBLIC_URL + "/images/home/falkor.png"}
                alt="falkor-img"
              />
            </div>
            <ul className="submenu">
              <li
                onClick={() =>
                  goToSubPageFromMenu("/directors-note", navigate, props, props)
                }
              >
                <div className="menu-title">
                  <h2>A Note From Our Executive Director</h2>
                  <img
                    className="menu-falkor"
                    src={process.env.PUBLIC_URL + "/images/home/falkor.png"}
                    alt="falkor-img"
                  />
                </div>
              </li>
              <li
                onClick={() => {
                  scrollToSection(props.byTheNumbersRef, navigate, props);
                }}
              >
                <div className="menu-title">
                  <h2>
                    10 Years of R/V{" "}
                    <span className="falkor-format">Falkor</span>
                  </h2>
                  <img
                    className="menu-falkor"
                    src={process.env.PUBLIC_URL + "/images/home/falkor.png"}
                    alt="falkor-img"
                  />
                </div>
              </li>
              <li
                onMouseEnter={() => setSubmenuActivo(1)}
                onMouseLeave={() => setSubmenuActivo(0)}
              >
                <div className="menu-title">
                  <h2
                    onClick={() =>
                      scrollToSection(props.anthologyRef, navigate, props, -200)
                    }
                  >
                    A Scientific Anthology
                  </h2>
                  <img
                    className="menu-falkor"
                    src={process.env.PUBLIC_URL + "/images/home/falkor.png"}
                    alt="falkor-img"
                  />
                  <img
                    className="menu-arrow"
                    id="arrow-1"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/arrows/arrow-right-home.png"
                    }
                    onClick={(event) => updateSubmenu(event, 1)}
                    alt="arrow"
                  />
                </div>
                {submenuActivo == 1 ? (
                  <ul>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/mapping-the-seafloor",
                          navigate,
                          props
                        )
                      }
                    >
                      Mapping the Seafloor
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/frontiers-of-biodiversity",
                          navigate,
                          props
                        )
                      }
                    >
                      Frontiers of Biodiversity
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/stability-of-ocean-health",
                          navigate,
                          props
                        )
                      }
                    >
                      Stability of Ocean Health
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu("/power-of-nature", navigate, props)
                      }
                    >
                      Power of Nature
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/exploring-the-deep",
                          navigate,
                          props
                        )
                      }
                    >
                      Exploring the Deep
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/technologies-for-the-future",
                          navigate,
                          props
                        )
                      }
                    >
                      Technologies for the Future
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/the-human-effect",
                          navigate,
                          props
                        )
                      }
                    >
                      The Human Effect
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li
                onMouseEnter={() => setSubmenuActivo(2)}
                onMouseLeave={() => setSubmenuActivo(0)}
              >
                <div className="menu-title">
                  <h2
                    onClick={() =>
                      scrollToSection(props.deepSeaRef, navigate, props, -200)
                    }
                  >
                    The Deep Sea Continuum
                  </h2>
                  <img
                    className="menu-falkor"
                    src={process.env.PUBLIC_URL + "/images/home/falkor.png"}
                    alt="falkor-img"
                  />
                  <img
                    className="menu-arrow"
                    id="arrow-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/arrows/arrow-right-home.png"
                    }
                    onClick={(event) => updateSubmenu(event, 2)}
                    alt="arrow"
                  />
                </div>
                {submenuActivo == 2 ? (
                  <ul>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu("/grants", navigate, props)
                      }
                    >
                      Grants
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/strategic-partnerships",
                          navigate,
                          props
                        )
                      }
                    >
                      Strategic Partnerships
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu("/leadership", navigate, props)
                      }
                    >
                      Leadership
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li
                onMouseEnter={() => setSubmenuActivo(3)}
                onMouseLeave={() => setSubmenuActivo(0)}
              >
                <div className="menu-title">
                  <h2
                    onClick={() =>
                      scrollToSection(props.disseminatingRef, navigate, props)
                    }
                  >
                    Disseminating the Depths
                  </h2>
                  <img
                    className="menu-falkor"
                    src={process.env.PUBLIC_URL + "/images/home/falkor.png"}
                    alt="falkor-img"
                  />
                  <img
                    className="menu-arrow"
                    id="arrow-3"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/arrows/arrow-right-home.png"
                    }
                    onClick={(event) => updateSubmenu(event, 3)}
                    alt="arrow"
                  />
                </div>
                {submenuActivo == 3 ? (
                  <ul>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu("/artists-at-sea", navigate, props)
                      }
                    >
                      Artist-at-Sea
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu("/awards", navigate, props)
                      }
                    >
                      Awards
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu("/museum-exhibits", navigate, props)
                      }
                    >
                      Museum Exhibits
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/press-and-footage",
                          navigate,
                          props
                        )
                      }
                    >
                      Press and Footage
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </li>
          <li
            className="menu-item"
            onClick={() => goHomeFromMenu(props, navigate)}
          >
            <div className="menu-title">
              <h1>Our Never Ending Journey</h1>
              <img
                className="menu-falkor"
                src={process.env.PUBLIC_URL + "/images/home/falkor.png"}
                alt="falkor-img"
              />
              <img
                className="menu-falkor-too"
                src={process.env.PUBLIC_URL + "/images/home/falkor_too.png"}
                alt="falkor-too-img"
              />
            </div>
          </li>
          <li className="menu-item has-children">
            <div className="menu-title">
              <h1>Visions of the Future</h1>
              <img
                className="menu-falkor-too"
                src={process.env.PUBLIC_URL + "/images/home/falkor_too.png"}
                alt="falkor-too-img"
              />
            </div>
            <ul className="submenu">
              <li
                onClick={() =>
                  goToSubPageFromMenu(
                    "/words-from-our-founders",
                    navigate,
                    props
                  )
                }
              >
                <div className="menu-title">
                  <h2>A Note From Our Founders</h2>
                  <img
                    className="menu-falkor-too"
                    src={process.env.PUBLIC_URL + "/images/home/falkor_too.png"}
                    alt="falkor-too-img"
                  />
                </div>
              </li>
              <li
                onMouseEnter={() => setSubmenuActivo(4)}
                onMouseLeave={() => setSubmenuActivo(0)}
              >
                <div className="menu-title menu-special-title">
                  <h2
                    onClick={() =>
                      scrollToSection(props.soiRef, navigate, props, -400)
                    }
                  >
                    SOI Remastered
                  </h2>
                  <img
                    className="menu-falkor-too"
                    src={process.env.PUBLIC_URL + "/images/home/falkor_too.png"}
                    alt="falkor-too-img"
                  />
                  <img
                    className="menu-arrow"
                    id="arrow-4"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/arrows/arrow-right-home.png"
                    }
                    onClick={(event) => updateSubmenu(event, 4)}
                    alt="arrow"
                  />
                </div>
                {submenuActivo == 4 ? (
                  <ul>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/10-year-soi-expeditions-map",
                          navigate,
                          props
                        )
                      }
                    >
                      10-year SOI Expeditions Map
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/new-proposal-process",
                          navigate,
                          props
                        )
                      }
                    >
                      New Proposal Process
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu(
                          "/celebrating-a-seaworthy",
                          navigate,
                          props
                        )
                      }
                    >
                      Celebrating a Seaworthy R/V{" "}
                      <span className="falkor-format">Falkor (too)</span>
                    </li>
                    <li
                      className="sub-submenu"
                      onClick={() =>
                        goToSubPageFromMenu("/rv-falkor-too", navigate, props)
                      }
                    >
                      R/V <span className="falkor-format">Falkor (too)</span>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li>
                <div className="menu-title">
                  <h2
                    onClick={() =>
                      goToSubPageFromMenu(
                        "/the-future-faces-of-ocean-science",
                        navigate,
                        props
                      )
                    }
                  >
                    The Future Faces of Ocean Science
                  </h2>
                  <img
                    className="menu-falkor-too"
                    src={process.env.PUBLIC_URL + "/images/home/falkor_too.png"}
                    alt="falkor-too-img"
                  />
                </div>
              </li>
              <li
                onClick={() =>
                  goToSubPageFromMenu(
                    "/a-limitless-ocean-of-data",
                    navigate,
                    props
                  )
                }
              >
                <div className="menu-title">
                  <h2>A Limitless Ocean of Data</h2>
                  <img
                    className="menu-falkor-too"
                    src={process.env.PUBLIC_URL + "/images/home/falkor_too.png"}
                    alt="falkor-too-img"
                  />
                </div>
              </li>
            </ul>
          </li>
          <li className="menu-item">
            <div className="menu-title">
              <h1><a href={process.env.PUBLIC_URL+"/ImpactReport2022.pdf"} target="_blank">Download the Report</a></h1>
              <img
                className="menu-falkor-too"
                src={process.env.PUBLIC_URL + "/images/home/falkor_too.png"}
                alt="falkor-too-img"
              />
            </div>
          </li>
        </ul>
      </nav>
      <div className="menu-close-btn" onClick={() => props.updateMenu()}>
        <FontAwesomeIcon icon={faClose} />
      </div>
      <div className="menu-logo-container">
        <img
          className="menu-logo"
          src={process.env.PUBLIC_URL + "/images/home/logo.png"}
          alt="logo-soi"
        />
      </div>
    </div>
  );
}

export default Menu;
