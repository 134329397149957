import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Technologies() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/technologies-rec1.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/adaptive-robotics-at-barkley-canyon-and-hydrate-ridge/"
            target="_blank"
          >
            Adaptive Robotics at Barkley Canyon and Hydrate Ridge
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Blair Thornton, University of Southampton
        </h4>
        <p>
          In 2018, the science and engineering team on board R/V{" "}
          <span className="falkor-format">Falkor</span> tested four robotic
          vehicles working together in tandem: ROV <span className="falkor-format">SuBastian</span> and
          three autonomous underwater vehicles (AUVs). The research took place at Barkley Canyon in the Northeastern Pacific Basin. The expedition
          conducted mission planning with multiple robots and demonstrated that
          the simultaneous use of these robotics could facilitate adaptable
          underwater surveys for ocean exploration. Different capabilities of
          the robots were utilized to image the seafloor and gather in situ
          chemical measurements. During the expedition, the team analyzed the large datasets collected by the robots and were able to adapt their mission plans rapidly. Once on shore,{" "}
          <a
            className="link-int-page"
            href="https://ieeexplore.ieee.org/document/9669070"
            target="_blank"
          >
            an algorithm was developed
          </a>{" "}
          to improve the speed at which seafloor features can be identified and
          annotated, compared to when humans annotate seafloor features with no
          computer assistance.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/technologies-rec2.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/designing-the-future-2/"
            target="_blank"
          >
            Designing the Future 2
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Brennan Phillips, University of Rhode Island
        </h4>
        <p>
          In 2021, R/V <span className="falkor-format">Falkor</span> ventured
          off the coast of San Diego, California, to test three next-generation
          technologies integrated onto ROV{" "}
          <span className="falkor-format">SuBastian</span>. The technologies were focused on studying the Ocean’s midwater organisms. Animals from the midwater are notoriously
          challenging to study as they often disintegrate by the time
          collections reach the surface. The first technology was a refined
          version of the{" "}
          <a
            className="link-int-page"
            href="https://web.uri.edu/uril/rad2/"
            target="_blank"
          >
            Rotary Actuated Dodecahedron (RAD2)
          </a>
          , developed by Dr. Phillips and his collaborators, a sphere-like
          origami robot that surrounds and captures delicate open-ocean animals
          like jellyfish, preserving their tissue in their natural environment.
          The second piece of technology, developed by engineers at Monterey Bay
          Aquarium Research Institute, was a laser and optics instrument called the{" "}
          <a
            className="link-int-page"
            href="https://www.mbari.org/technology/deeppiv/"
            target="_blank"
          >
            Deep Particle Image Velocimetry
          </a>{" "}
          (DeepPIV). The DeepPIV creates a sheet of light and makes 3D scans of
          underwater animals, measuring their fine-scale movements. Lastly, the
          scientists tested the Eye Remote Imaging System (EyeRIS), a new
          real-time 3D imaging lens also developed at the Monterey Bay Aquarium
          Research Institute. The three technologies were used to rapidly
          characterize delicate animals living in the midwater environment and
          demonstrate an effective workflow for studying this part of the water
          column. The samples and data collected from this expedition are still
          being analyzed by the research team.
        </p>
      </div>
    </>
  );
}

export default Technologies;
