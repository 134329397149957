import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Deep() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL + "/images/subpages/internas/deep-rec1.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/exploring-the-mariana-trench/"
            target="_blank"
          >
            Exploring the Mariana Trench
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Jeffrey Drazen, University of Hawai’i at Mānoa
        </h4>
        <p>
          In 2014, R/V <span className="falkor-format">Falkor</span> visited the
          Mariana Trench to study extreme deep-sea ecosystems, and the
          physiological adaptations organisms have evolved to withstand living
          in a high-pressure environment. During the expedition,{" "}
          <a
            className="link-int-page"
            href="https://link.springer.com/article/10.1007/s00227-021-03950-8"
            target="_blank"
          >
            the deepest living fish
          </a>{" "}
          at the time of discovery,{" "}
          <a
            className="link-int-page"
            href="https://www.sciencedirect.com/science/article/pii/S0967063716300656"
            target="_blank"
          >
            the Mariana Snailfish
          </a>,{" "}
          was filmed and collected. The snailfish is translucent and uses small
          pressure-stabilizing molecules to survive under high pressure.
          Specimens from the expedition are still being utilized to study{" "}
          <a
            className="link-int-page"
            href="https://academic.oup.com/jcb/article/41/1/ruaa102/6128500"
            target="_blank"
          >
            how and where organisms live in high pressure environments
          </a>
          ,{" "}
          <a
            className="link-int-page"
            href="https://www.sciencedirect.com/science/article/pii/S0967063720301473?via%3Dihub"
            target="_blank"
          >
            their interactions in the deep-sea community
          </a>
          , and the{" "}
          <a
            className="link-int-page"
            href="https://www.sciencedirect.com/science/article/pii/S0967063721001795?via%3Dihub"
            target="_blank"
          >
            hadal ecosystem
          </a>
          . Scientists continue to research the{" "}
          <a
            className="link-int-page"
            href="https://frontiersinzoology.biomedcentral.com/articles/10.1186/s12983-021-00399-9"
            target="_blank"
          >
            snailfish’s bone structure and how individual molecules within the
            bone react and respond to intense pressure
          </a>
          . Additionally, fauna collected had significant levels of{" "}
          <a
            className="link-int-page"
            href="https://www.sciencedirect.com/science/article/pii/S2405844018342762"
            target="_blank"
          >
            mercury
          </a>{" "}
          and{" "}
          <a
            className="link-int-page"
            href="https://www.nature.com/articles/s41559-016-0051"
            target="_blank"
          >
            plastic
          </a>{" "}
          in their systems. The presence of pollutants in snailfish reminds us
          again that human actions impact animals living in the deepest part of
          our world.
        </p>
      </div>

      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL + "/images/subpages/internas/deep-rec2.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/costa-rican-deep-sea-connections/"
            target="_blank"
          >
            Costa Rican Deep Sea Connections
          </a>
        </h3>
        <h4 className="subpage-rec-subtitle">
          Chief Scientist: Dr. Erik Cordes, Temple University
        </h4>
        <p>
          In 2019, R/V <span className="falkor-format">Falkor</span> aided in
          some of the first-ever research in Costa Rica’s deep waters. The goal
          of the expedition was to document the ecosystems on seamounts
          surrounding the Cocos Island National Park, which was, at the time,
          thought to be a key information gap in preventing the expansion of the
          protected area. Many Costa Ricans, including government officials, saw
          their country’s deepwater ecosystems for the first time during the
          expedition when SOI live-streamed the exploration. The data collected
          contributed to the expansion of the{" "}
          <a
            className="link-int-page"
            href="https://www.youtube.com/watch?v=74xYpRFAzv8&t=954s"
            target="_blank"
          >
            Cocos Island National Park in 2021
          </a>
          . The park expansion protects critical biodiversity hotspots and
          provides safe habitat for species, distributing the benefits of a
          healthy ecosystem across the entire Eastern Tropical Pacific region.
          Researchers continue to use the data collected to examine the
          distribution of deep-sea corals on Costa Rica’s seamounts. The
          exploration also yielded several new species, including the{" "}
          <a
            className="link-int-page"
            href="https://zookeys.pensoft.net/article/48532/"
            target="_blank"
          >
            hungry scale worm
          </a>{" "}
          and new species of{" "}
          <a
            className="link-int-page"
            href="https://www.sciencedirect.com/science/article/pii/S0967063720301102?via%3Dihub#fig6"
            target="_blank"
          >
            mussels
          </a>{" "}
          and{" "}
          <a
            className="link-int-page"
            href="https://europeanjournaloftaxonomy.eu/index.php/ejt/article/view/1959"
            target="_blank"
          >
            ribbon worms
          </a>
          .
        </p>
      </div>
    </>
  );
}

export default Deep;
