import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Press() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/press-rec1.webp"
          }
          alt="img"
        />
        <p>
          Previous expedition results and partnership announcements made the
          headlines in 2022, with 198 news stories reaching 940 million people
          in 25 countries.
        </p>

        <p>
          SOI images were used in an impressive array of educational and public
          projects in 2022. A new simulation game based on real-life ROV dive
          missions, <span className="falkor-format">SubROV</span>, was released
          in December by José Luis González Castro. Two of the game's missions
          are based on ROV <span className="falkor-format">SuBastian</span>{" "}
          dives that took place during the{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/biodiverse-borderlands/"
            target="_blank"
          >
            Biodiverse Borderlands
          </a>{" "}
          and{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/discovering-deep-sea-corals-of-the-phoenix-islands-2/"
            target="_blank"
          >
            Deep Sea Corals of the Phoenix Islands
          </a>{" "}
          expeditions. Many of the graphics and artwork were inspired by species
          filmed with ROV <span className="falkor-format">SuBastian</span>. You
          can learn more about <span className="falkor-format">SubROV</span> or{" "}
          <a
            className="link-int-page"
            href="https://store.steampowered.com/app/1648760/subROV__Underwater_Discoveries/?fbclid=IwAR2wmxyXERi1wdEqd5aGFGLpW5p_gUyi9tISNE76Cn3u9F0e0YOIgU6vSaA"
            target="_blank"
          >
            download the game here
          </a>
          .
        </p>

        <p>
          Another example is a coffee table book by Phaidon Press,{" "}
          <span className="falkor-format">
            Ocean: Exploring the Marine World
          </span>
          . This 352-page illustrated timeline of Ocean art includes an SOI
          photo of a hydrothermal vent discovered on the{" "}
          <a
            className="link-int-page"
            href="https://schmidtocean.org/cruise/linking-microbial-communities-environmental-drivers/"
            target="_blank"
          >
            Microbial Mysteries
          </a>{" "}
          expedition. SOI’s deep sea footage was also used during a concert
          titled{" "}
          <a
            className="link-int-page"
            href="https://kunstnatur.art/"
            target="_blank"
          >
            <span className="falkor-format">Deep Sea Dreams</span>
          </a>{" "}
          at the Kunstverein Offenbach in Germany. Additionally, Australian
          surf-inspired streetwear brand,{" "}
          <a
            className="link-int-page"
            href="https://www.junglesjungles.com/"
            target="_blank"
          >
            Jungles Jungles
          </a>
          , used SOI imagery through a collaboration with Nautilus Magazine. The
          designers created a clothing line, “No Species is an Island,” to raise
          awareness for the 30×30 campaign, an initiative focused on reserving
          30% of the Ocean as marine protected areas by 2030.
        </p>
      </div>
    </>
  );
}

export default Press;
