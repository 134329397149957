import "../../../stylesheets/SubPage.css";
import React, { useRef, useEffect } from "react";

function Artists() {
  return (
    <>
      <div className="subpage-recuadro general-text">
        <img
          className="float with-border"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/artists-rec1.webp"
          }
          alt="img"
        />
        <h3 className="subpage-rec-title mt-0">First Remote Artist-at-Sea</h3>

        <p>
          In 2022, the Artist-at-Sea program continued during the refit of R/V{" "}
          <span className="falkor-format">Falkor (too)</span>, welcoming its
          first remote artist,{" "}
          <a
            className="link-int-page"
            href="https://www.taijiterasaki.com/"
            target="_blank"
          >
            Taiji Terasaki
          </a>
          . The Hawai’i-based multimedia artist worked with nine images of
          species photographed by ROV{" "}
          <span className="falkor-format">SuBastian</span>, such as the glass
          octopus and vampire squid, to create his{" "}
          <span className="falkor-format">Vanishing Point</span> series.{" "}
          <span className="falkor-format">Vanishing Point</span> walks the line
          between life's appearance and disappearance in the depths by merging
          six individual glass panels with the same species printed on them,
          each image gradually decreasing in opacity to form one whole artwork.
          One piece from the collection debuted at the UN Ocean Conference
          Artist-at-Sea exhibit and was donated to the{" "}
          <a
            className="link-int-page"
            href="https://www.pavconhecimento.pt/en/https://www.pavconhecimento.pt/en/"
            target="_blank"
          >
            Ciencia Viva museum
          </a>{" "}
          in Lisbon, Portugal. Three other pieces from the collection were
          showcased in a six-month art installation at the Port of Seattle.
        </p>
      </div>

      <div className="subpage-recuadro general-text flex-rec">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/artists-rec2.webp"
          }
          alt="img"
        />
        <div className="text-container">
            <h3 className="subpage-rec-title mt-0">Half-Light</h3>
            <p>
              Previous Artist-at-Sea, Ellie Hannon, and SOI multimedia correspondent
              Conor Ashleigh debuted an installation of immersive paintings, short
              films, and ceramics in the{" "}
              <a
                className="link-int-page"
                href="https://mrag.org.au/exhibition/half-light-ellie-hannon-conor-ashleigh/"
                target="_blank"
              >
                Half-Light exhibit
              </a>{" "}
              at the Maitland Regional Art Gallery in New South Wales, Australia.
              The exhibit was based on their time on R/V{" "}
              <span className="falkor-format">Falkor</span> during the 2021{" "}
              <a
                className="link-int-page"
                href="https://schmidtocean.org/cruise/australian-mesophotic-coral-examination/"
                target="_blank"
              >
                Australian Mesophotic Coral Examination
              </a>{" "}
              expedition. Half-Light takes a subsurface plunge offering views into
              natural spaces that humans are unable to visit in the Ocean’s twilight
              zone.
            </p>
        </div>
      </div>

      <div className="subpage-recuadro general-text flex-rec">
        <img
          className="float"
          src={
            process.env.PUBLIC_URL +
            "/images/subpages/internas/artists-rec3.webp"
          }
          alt="img"
        />
        <div className="text-container">
            <h3 className="subpage-rec-title mt-0">2022 Exhibitions</h3>
            <p>
              Three Artist-at-Sea exhibits were held in 2022, showcasing more than
              60 pieces from SOI’s growing collection. In March, in collaboration
              with Nautilus, a one-week exhibit was held at the Explorers Club in
              New York City, New York, during the UN{" "}
              <a
                className="link-int-page"
                href="https://www.un.org/bbnj/"
                target="_blank"
              >
                Intergovernmental Conference on Marine Biodiversity of Areas Beyond
                National Jurisdiction
              </a>
              . The opening event welcomed conference dignitaries and five previous
              Artist-at-Sea participants, including a musical performance by Ben
              Cosgrove, who{" "}
              <a
                className="link-int-page"
                href="https://schmidtocean.org/cruise-log-post/artist-sea-ben-cosgrove-smallness-stillness-motion/"
                target="_blank"
              >
                sailed on R/V <span className="falkor-format">Falkor</span>
              </a>{" "}
              during a transit in 2016. In June, two separate art exhibitions were
              displayed at the Ciencia Viva and Gulbenkian Museums as part of the UN
              Ocean Conference in Lisbon, Portugal. The last exhibit of the year was
              held at the Port of Seattle, which continued into 2023.
            </p>
        </div>
      </div>
    </>
  );
}

export default Artists;
